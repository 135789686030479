import { Container, Row } from 'react-bootstrap';
import Navigation from '../components/navigation/navigation';
import Headerslider from '../components/sliders/header/headerslider';
import WelcomeBanner from '../components/banners/welcomebanner';
import Footer from '../components/footer/footer';
import { getDataForHome, getDataPreviewForHome } from '../lib/api/getdataforhome';
import React, { ReactElement } from 'react';
import { ESHomeData } from '../types/elasticsearch';
import JournalBrandSlider from '../components/sliders/journalbrandslider/journalbrandslider';
import SeoSchema from '../components/seoschema/seoschema';
import SeoMetadata from '../components/seometadata/seometadata';
import { useRouter } from 'next/router';
import SeoText from '../components/seotext/seotext';
import Head from 'next/head';
import usePreviewPageExit from '../hooks/usePreviewPageExit';
import { withServerSideProps } from '../lib/withServerSideProps';
import HeaderBanner from '../components/navigation/headerBanner';
import styles from '../scss/pages/branddetail.module.scss';
import ButtonGoTop from '../components/buttons/topButton';

interface Props {
  initialData: ESHomeData;
  isPreview: boolean;
  cookies: string;
}

const Home = ({ initialData, isPreview, cookies }: Props): ReactElement => {
  const {
    sliderData,
    brandPosts,
    firstBanner,
    journalPosts,
    secondBanner,
    journalPostsHeadline,
    brandPostsHeadline,
    title,
    seoField,

    excerptCopy
  } = initialData;
  const router = useRouter();
  usePreviewPageExit(isPreview);
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='facebook-domain-verification' content='udblg7t7njijwryq6swzpkzitfvlk5' />
      </Head>
      <SeoMetadata
        title={firstBanner?.headline}
        description={excerptCopy ? excerptCopy : firstBanner?.text}
        imgUrl={`/assets/images/fairlyfab-logo-black.png`}
        isBrandPost={false}
        uri={router.pathname}
      />
      <SeoSchema journals={journalPosts} metaDescription={firstBanner?.text} brands={brandPosts} />
      <div className={'sticky-position'}>
        <Container fluid>
          <HeaderBanner cookies={cookies} />
        </Container>
        <div className={styles.header}>
          <Container className={styles.fixedWidth}>
            <Navigation isHome={true} />
          </Container>
        </div>
      </div>
      <Container className={styles.fixedWidth}>
        <Row>
          <Headerslider sliderData={sliderData} />
        </Row>
        <Row>
          <WelcomeBanner
            theme={firstBanner?.style}
            headline={firstBanner?.headline}
            text={firstBanner?.text}
            buttonText={firstBanner?.button?.textButton}
            buttonLink={firstBanner?.button?.linkButton}
          />
        </Row>
        <Row className={styles.journalBrandSlider}>
          <JournalBrandSlider title={brandPostsHeadline} brandPosts={brandPosts} />
        </Row>
        <Row>
          <WelcomeBanner
            theme={secondBanner?.style}
            headline={secondBanner?.headline}
            text={secondBanner?.text}
            buttonText={secondBanner?.button?.textButton}
            buttonLink={secondBanner?.button?.linkButton}
          />
        </Row>
        <Row className={styles.journalBrandSlider}>
          <JournalBrandSlider title={journalPostsHeadline} journals={journalPosts} />
        </Row>
        <Row className='justify-content-center mb-5'>
          <SeoText text={seoField} />
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <ButtonGoTop />
          <Footer />
        </Row>
      </Container>
    </>
  );
};

export const getServerSideProps = withServerSideProps({
  getData: getDataForHome,
  getPreviewData: getDataPreviewForHome
});

export default Home;
