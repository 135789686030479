import React from 'react';
import styles from './welcomebanner.module.scss';
import { Button, Row } from 'react-bootstrap';
import { WellcomeBannerTypes } from './welcomebanner.types';

const WelcomeBanner = (props: WellcomeBannerTypes) => {
  const { theme, headline, text, buttonText, buttonLink } = props;

  return (
    <div className={`col-12 ${theme === 'light' ? styles.lightTheme : styles.darkTheme}`}>
      <Row>
        <div className={`col-sm-12 mx-auto ${styles.bannerContent}`}>
          {theme === 'light' ? (
            <>
              <h2 className={`mx-md-auto ${styles.headline}`}>{headline}</h2>
              {text && <p className={styles.text}>{text}</p>}
            </>
          ) : (
            <>
              <h2 className={`mx-md-auto ${styles.headline}`}>{headline}</h2>
              <p className={styles.darkBannertext}>{text}</p>
            </>
          )}
          <a href={buttonLink} className={styles.linkText}>
            <Button
              block
              variant={`${theme === 'light' ? 'dark' : 'light'}`}
              className={`mx-auto ${styles.linkButton}  ${
                theme === 'light' ? styles.darkButton : styles.lightButton
              }`}
            >
              {buttonText}
            </Button>
          </a>
        </div>
      </Row>
    </div>
  );
};

export default WelcomeBanner;
