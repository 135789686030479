import React, { ReactElement, useState } from 'react';
import styles from '../seotext/seotext.module.scss';

interface Props {
  text: string;
}

const SeoText = ({ text }: Props): ReactElement => {
  const [showLess, setShowLess] = useState(true);
  const moreTextLength = text?.indexOf('<!--more-->');

  //@ts-ignore
  const onClickHandler = () => {
    if (showLess === false) {
      const seoText = document.querySelector(`#seotext`);
      // @ts-ignore
      window.scrollTo({ top: seoText?.offsetTop, behavior: 'smooth' });
    }
    setShowLess(!showLess);
  };

  const smartText = (
    text: string,
    length = moreTextLength > -1 ? moreTextLength : 2025
  ): ReactElement => {
    if (text.length < length) {
      return (
        <div
          className={`col-12 col-md-10 ${styles.seoText}`}
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />
      );
    }

    return (
      <>
        <div
          className={`col-12 col-md-10 ${styles.seoText}  ${showLess ? styles.opacityText : ''}`}
          dangerouslySetInnerHTML={{
            __html: text.slice(0, length)
          }}
        />
        <div
          className={`col-12 col-md-10 ${styles.seoText} ${showLess ? 'd-none' : 'd-block'}`}
          dangerouslySetInnerHTML={{
            __html: text.slice(length, text.length)
          }}
        />

        <span
          className={`${styles.showMore} ${showLess ? styles.arrowUp : styles.arrowDown}`}
          onClick={() => onClickHandler()}
        />
      </>
    );
  };

  return (
    <div className={styles.mainContainer} id='seotext'>
      {smartText(text || '')}
    </div>
  );
};

export default SeoText;
