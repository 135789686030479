import React, { ReactElement } from 'react';
import styles from './headerslider.module.scss';
// import Image from 'next/image';
import { Button, Carousel, Col, Image } from 'react-bootstrap';
import { brandRating, changeImgSrc } from '../../../utils/utils';
import { BrandLeft, BrandRight, SliderData } from '../../../types/elasticsearch';
import Link from 'next/link';

interface Props {
  sliderData: SliderData[];
}

const Headerslider = ({ sliderData }: Props): ReactElement => {
  const numberBtn = sliderData[0].textWithButtonsAndImageRight?.buttons?.length;

  const renderImageWithBrand = (item: BrandLeft | BrandRight): ReactElement => {
    if (!item.imageDesktop || !item.imageMobile) return <></>;

    return (
      <>
        <div style={{ height: '100%', width: '100%' }} className={'d-none d-sm-block'}>
          <Image
            src={changeImgSrc(item.imageDesktop?.mediaItemUrl ?? '')}
            className={` ${styles.imageStyle}`}
            alt={item.imageDesktop?.altText ? item.imageDesktop?.altText : item.imageDesktop?.title}
          ></Image>
        </div>
        <div style={{ height: '100%', width: '100%' }} className={'d-sm-none'}>
          {item.imageMobile?.mediaItemUrl && (
            <Image
              src={changeImgSrc(item.imageMobile?.mediaItemUrl ?? '')}
              className={` ${styles.imageStyle}`}
              alt={item.imageMobile?.altText ? item.imageMobile?.altText : item.imageMobile?.title}
            ></Image>
          )}
        </div>
        <Carousel.Caption className={`row ${styles.carouselslider}`}>
          <Col>
            <span className={`border bg-light text-dark ${styles.oval}`}>
              {brandRating(item?.brand?.brand_detail?.overallRating)}
            </span>
            <h1 className={`${styles.brandTitle} ${styles.fontP}`}>{item?.brand?.title}</h1>
            <Link href={item?.brand?.uri}>
              <Button
                block
                variant={`light`}
                className={`mx-auto ${styles.linkButton}  ${styles.lightButton}`}
              >
                Zum Shop
              </Button>
            </Link>
          </Col>
        </Carousel.Caption>
      </>
    );
  };
  return (
    <Carousel
      className={`${styles.homesliderIndex} homeslider w-100`}
      controls={false}
      indicators={sliderData?.length > 1 ? true : false}
      interval={null}
    >
      {sliderData.map((item) => {
        const sliderVariant = item.chooseModule;
        if (sliderVariant === 'oneBrandFullWidth') {
          if (!item.oneBrandFullWidth.imageDesktop) return <></>;
          return (
            <Carousel.Item key={item.oneBrandFullWidth?.imageDesktop?.id}>
              {renderImageWithBrand(item.oneBrandFullWidth)}
            </Carousel.Item>
          );
        } else if (sliderVariant === 'twoBrands') {
          if (!item.twoBrands.brandLeft.imageDesktop) return <></>;
          return (
            <Carousel.Item key={item.twoBrands.brandLeft.imageDesktop.id}>
              <div className='row'>
                <Col sm={6} style={{ padding: '0' }}>
                  {renderImageWithBrand(item.twoBrands.brandLeft)}
                </Col>
                <Col style={{ padding: '0' }}>
                  {renderImageWithBrand(item.twoBrands.brandRight)}
                </Col>
              </div>
            </Carousel.Item>
          );
        } else if (sliderVariant === 'textWithButtonsAndImageRight') {
          if (!item.textWithButtonsAndImageRight.imageRight) return <></>;
          const buttons = item.textWithButtonsAndImageRight.buttons;
          return (
            <Carousel.Item key={item.textWithButtonsAndImageRight?.imageRight?.id}>
              <div className='row'>
                <Col xs={12}>
                  <div style={{ height: '100%', width: '100%' }} className={'d-sm-none'}>
                    {item.textWithButtonsAndImageRight?.imageMobile?.mediaItemUrl && (
                      <Image
                        src={changeImgSrc(
                          item.textWithButtonsAndImageRight?.imageMobile?.mediaItemUrl
                        )}
                        className={` ${styles.imageStyle} resposive`}
                        alt={
                          item.textWithButtonsAndImageRight?.imageMobile?.altText
                            ? item.textWithButtonsAndImageRight?.imageMobile?.altText
                            : item.textWithButtonsAndImageRight?.imageMobile?.title
                        }
                      ></Image>
                    )}
                  </div>
                </Col>
                <Col sm={6} style={{ padding: '0' }}>
                  <div className={styles.colorField}>
                    <Carousel.Caption className={`row ${styles.carouselslider}`}>
                      <Col sm={12}>
                        <h2 className={styles.sliderTitle}>
                          {item.textWithButtonsAndImageRight.text}
                        </h2>
                      </Col>
                      <div className={`col-sm-12 row ${styles.buttons}`}>
                        {buttons.map((button, idx) => (
                          <div
                            className={`${numberBtn === 3 ? 'col-sm-4' : ''} ${
                              buttons.length > 1 && buttons.length !== 3 ? 'col-sm-6' : ''
                            } ${buttons.length === 1 ? 'col-sm-12' : ''}${styles.buttons}`}
                            key={idx}
                          >
                            <Button
                              href={
                                button.pageOrLinkUrl === 'url'
                                  ? button?.buttonLinkUrl
                                  : button?.buttonLinkPage?.uri
                              }
                              block
                              variant={`light`}
                              className={`mx-auto ${styles.linkTextButton}  ${styles.lightButton}`}
                            >
                              {button.buttonText}
                            </Button>
                          </div>
                        ))}
                      </div>
                    </Carousel.Caption>
                  </div>
                </Col>
                <Col style={{ padding: '0' }}>
                  <div style={{ height: '100%', width: '100%' }} className={'d-none d-sm-block'}>
                    <Image
                      src={changeImgSrc(item.textWithButtonsAndImageRight.imageRight.mediaItemUrl)}
                      className={` ${styles.imageStyle} resposive`}
                      alt={
                        item.textWithButtonsAndImageRight?.imageRight?.altText
                          ? item.textWithButtonsAndImageRight?.imageRight?.altText
                          : item.textWithButtonsAndImageRight?.imageRight?.title
                      }
                    ></Image>
                  </div>
                </Col>
              </div>
            </Carousel.Item>
          );
        }
      })}
    </Carousel>
  );
};

export default Headerslider;
